.containerNav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
}
.containerNav button {
  color: #fff;
  font-size: 14px;
  /* margin: 5px; */
  padding: 4px 7px;
  background: #1717178f ;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}
.owlStageOuterSpec{
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
}
